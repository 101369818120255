import * as classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

// components
import { Container } from '../container/Container';

// styles
import * as styles from './Hero2.sass';

interface IProps {
  className?: string;
}

export const Hero2: React.SFC<IProps> = ({ className }) => {
  const images = [
    '../../assets/hero-burger-banner.png',
    '.../../assets/hero-gift-card.png',
    '../../assets/hero-tshirts.png'
  ];

  // const links = [
  //   '/menu',
  //   '/merch',
  //   '/merch'
  // ];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const nextSlide = () => {
    if (currentSlideIndex === images.length - 1) {
      setCurrentSlideIndex(0);
    } else {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const previousSlide = () => {
    if (currentSlideIndex === 0) {
      setCurrentSlideIndex(images.length - 1);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const numberClick = (indexOfNumberClicked: number) => {
    setCurrentSlideIndex(indexOfNumberClicked);
  };

  const getBarStatus = () => {
    if (currentSlideIndex === 0) {
      return styles.activeBar;
    } else if (currentSlideIndex === 1) {
      return styles.activeBar2;
    } else if (currentSlideIndex === 2) {
      return styles.activeBar3;
    } else {
      return styles.activeBar;
    }
  };

  // interface IBarProps {
  //   className?: string;
  // }

  // const Bar: React.SFC<IBarProps> = () => {
  //   return (
  //     <>
  //       <div className={`styles.bar ${currentSlideIndex === 0 ? styles.activeBar : ''}`} />
  //       <div className={`styles.bar ${currentSlideIndex === 1 ? styles.activeBar : ''}`} />
  //       <div className={`styles.bar ${currentSlideIndex === 2 ? styles.activeBar : ''}`} />
  //     </>
  //   );
  // };

  return (
    <div className={classNames(styles.hero, className)}>
      <Container className={styles.heroContainer}>
        <div className={styles.arrowContainer} onClick={() => previousSlide()}>
          <img className={styles.arrow} src="../../assets/left-arrow.png" />
        </div>
        <div className={styles.info}>
          {/* <div className={styles.heroInfoImageContainer}>
            <img src="../../assets/placeholder-image.png" />
          </div> */}
          <div className={styles.heroInfoContainer}>
            <div>
              <h1>Arlo's</h1>
              <h1>curbside</h1>
              <h1>kitchen</h1>
            </div>
            <p>
              Arlo’s serves plant-based comfort food in a bistro style setting for the Austin
              nightlife scene. Our food is designed to satisfy omnivores and herbivores alike. We
              offer a fantastic alternative to the usual late night trailer eateries.
            </p>
            <div className={styles.buttonContainer}>
              <Link to="/menu">More</Link>
            </div>
          </div>
        </div>

        <div className={styles.centerContainer}>
          <img className={styles.heroBanner} src="../../assets/hero-banner.svg" />
          {/* <a href={links[currentSlideIndex]}> */}
          {currentSlideIndex === 0 && <Link to="/order"><img className={styles.heroImage} src={images[currentSlideIndex]} /></Link>}
          {currentSlideIndex === 1 && <Link to="/gift-cards"><img className={styles.heroImage} src={images[currentSlideIndex]} /></Link>}
          {currentSlideIndex === 2 && <img className={styles.heroImage} src={images[currentSlideIndex]} />}
          {/* </a> */}
          <div className={styles.photoIndicatorContainer}>
            <div className={styles.photoNumbersContainer}>
              <div
                className={classNames(
                  styles.photoNumber,
                  currentSlideIndex === 0 ? styles.active : null
                )}
                onClick={() => numberClick(0)}
              >
                01
              </div>
              <div>-</div>
              <div
                className={classNames(
                  styles.photoNumber,
                  currentSlideIndex === 1 ? styles.active : null
                )}
                onClick={() => numberClick(1)}
              >
                02
              </div>
              <div>-</div>
              <div
                className={classNames(
                  styles.photoNumber,
                  currentSlideIndex === 2 ? styles.active : null
                )}
                onClick={() => numberClick(2)}
              >
                03
              </div>
            </div>
            <div className={styles.barContainer}>
              <div>
                {/* <Bar /> */}
                {/* <>
                  <div className={`${currentSlideIndex === 0 ? styles.activeBar : styles.bar}`} />
                  <div className={`${currentSlideIndex === 1 ? styles.activeBar : styles.bar}`} />
                  <div className={`${currentSlideIndex === 2 ? styles.activeBar : styles.bar}`} />
                </> */}
                {/* <div
                  className={`${currentSlideIndex === 0 ? styles.activeBar : styles.activeBar2}`}
                /> */}
                <div className={styles.grayBar} />
                <div className={`${getBarStatus()}`} />

              </div>
            </div>
          </div>
        </div>
        <div />

        <div className={styles.arrowContainerRight} onClick={() => nextSlide()}>
          <img className={styles.arrow} src="../../assets/right-arrow.png" />
        </div>
      </Container>
    </div>
  );
};
