import * as classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Contact.sass';

interface IProps {
  className?: string;
}

export const Contact: React.SFC<IProps> = ({ className }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [mailingList, setMailingList] = useState(false);
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await fetch('https://contact.arlos.workers.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          topic,
          mailingList,
          message
        })
      });

      setIsComplete(true);
      setName('');
      setEmail('');
      setTopic('');
      setMailingList(false);
      setMessage('');
    } catch (error) {
      setErrorMessage(error.message);
    }

    setIsLoading(false);
  }

  if (isComplete) {
    return (
      <div className={classNames(styles.main, className)}>
        <Container>
          <div className={styles.container}>
            <span className={styles.letsText}>Let's</span>&nbsp;
            <span className={styles.connectText}>connect</span>
          </div>
          <img className={styles.tomato} src="../../assets/tomato.png" />
          <img className={styles.salad} src="../../assets/salad.png" />
          <form onSubmit={handleSubmit}>
            {errorMessage}
            <div className={styles.gridContainer}>
              <div className={styles.formLeft}>
                <div>
                  <label>Your Name</label>
                </div>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className={styles.flexContainer}>
                  <label>Email Address</label>
                  <div>
                    <input
                      type="checkbox"
                      name="mailingList"
                      onChange={(e) => setMailingList(e.target.checked)}
                    />{' '}
                    add to mailing list
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Topic</label>
                </div>
                <div className={classNames(styles.inputContainer, styles.customSelect)}>
                  <select
                    id="topic"
                    name="topic"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    required
                  >
                    <option value="addToMailingList">Add To Mailing List</option>
                    <option value="events">Events</option>
                    <option value="questions">General Inquiries</option>
                  </select>
                </div>
              </div>
              <div className={styles.contactPhoto} />
            </div>
            <div className={styles.formBottom}>
              <div>
                <label>Your Message</label>
              </div>
              <div>
                <textarea
                  id="subject"
                  name="subject"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
              <div>
                <button className={styles.submit} type="submit" value="Submit" disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
                <div className={styles.thankYou}>Thank you!</div>
              </div>
            </div>
          </form>
        </Container>
      </div>
    );
  }

  return (
    <div className={classNames(styles.main, className)}>
      <Container>
        <div className={styles.container}>
          <span className={styles.letsText}>Let's</span>&nbsp;
          <span className={styles.connectText}>connect</span>
        </div>
        <img className={styles.tomato} src="../../assets/tomato.png" />
        <img className={styles.salad} src="../../assets/salad.png" />
        <form onSubmit={handleSubmit}>
          <div className={styles.gridContainer}>
            <div className={styles.formLeft}>
              <div>
                <label>Your Name</label>
              </div>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className={styles.flexContainer}>
                <label>Email Address</label>
                <div>
                  <input
                    type="checkbox"
                    name="mailingList"
                    onChange={(e) => setMailingList(e.target.checked)}
                  />{' '}
                  add to mailing list
                </div>
              </div>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Topic</label>
              </div>
              <div className={classNames(styles.inputContainer, styles.customSelect)}>
                <select
                  id="topic"
                  name="topic"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  required
                >
                  <option value="events">Events</option>
                  <option value="questions">General Inquiries</option>
                </select>
              </div>
            </div>
            <div className={styles.contactPhoto} />
          </div>
          <div className={styles.formBottom}>
            <div>
              <label>Your Message</label>
            </div>
            <div>
              <textarea
                id="subject"
                name="subject"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <div>
              <button className={styles.submit} type="submit" value="Submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
              {errorMessage}
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};
