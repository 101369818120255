exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrderPage__jalapeno__2BrIk {\n  position: absolute;\n  left: 30px;\n  width: 130px;\n  margin-top: -60px; }\n  @media screen and (max-width: 1000px) {\n    .OrderPage__jalapeno__2BrIk {\n      display: none; } }\n\n.OrderPage__menu__33Ueg {\n  margin: 0 auto; }\n\n.OrderPage__onion__37W_F {\n  position: absolute;\n  right: -30px;\n  margin-top: 1200px; }\n  @media screen and (max-width: 1000px) {\n    .OrderPage__onion__37W_F {\n      display: none; } }\n\n.OrderPage__pickles__KSE2h {\n  position: absolute;\n  right: 10%;\n  width: 150px;\n  margin-top: 300px; }\n  @media screen and (max-width: 1000px) {\n    .OrderPage__pickles__KSE2h {\n      display: none; } }\n\n.OrderPage__salad__23SO6 {\n  position: absolute;\n  left: 0;\n  width: 120px;\n  margin-top: 600px; }\n  @media screen and (max-width: 1000px) {\n    .OrderPage__salad__23SO6 {\n      display: none; } }\n\n.OrderPage__tomato__2nVmX {\n  position: absolute;\n  left: -60px;\n  margin-top: 100px; }\n  @media screen and (max-width: 1000px) {\n    .OrderPage__tomato__2nVmX {\n      margin-top: 50px;\n      width: 150px; } }\n  @media screen and (max-width: 768px) {\n    .OrderPage__tomato__2nVmX {\n      display: none; } }\n", ""]);

// exports
exports.locals = {
	"jalapeno": "OrderPage__jalapeno__2BrIk",
	"menu": "OrderPage__menu__33Ueg",
	"onion": "OrderPage__onion__37W_F",
	"pickles": "OrderPage__pickles__KSE2h",
	"salad": "OrderPage__salad__23SO6",
	"tomato": "OrderPage__tomato__2nVmX"
};