exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalLink__button__1-cla {\n  background: none;\n  border: 0;\n  cursor: pointer;\n  display: inline;\n  font-size: 1rem;\n  margin: 0;\n  padding: 0; }\n", ""]);

// exports
exports.locals = {
	"button": "ModalLink__button__1-cla"
};