import * as React from 'react';
import * as classNames from 'classnames';
import { useContext } from 'react';
import { ModalContext } from '../../contexts/modal';
import * as styles from './ModalLink.sass';

interface IProps {
  image?: string;
  size?: number;
  to?: string;
  type?: 'button' | 'submit';
  content: React.ReactNode;
  className?: string;
}

export const ModalLink: React.FC<IProps> = ({
  children,
  content,
  image,
  size = 24,
  type = 'button',
  className
}) => {
  const { setShowModal, setModalContent } = useContext(ModalContext);

  return (
    <>
      <div
        className={classNames(className, styles.button)}
        style={image ? {
          backgroundImage: `url(${process.env.PUBLIC_URL}${image})`,
          height: `${size}px`,
          width: `${size}px`
        } : null}
        // type={type}
        onClick={() => { setShowModal(true); setModalContent(content); }}
      >
        {children}
      </div>
    </>
  )
}
