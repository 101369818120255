import * as classNames from 'classnames';
import * as React from 'react';

// components
import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';

// styles
import * as styles from './MainTemplate.sass';

interface IProps {
  className?: string;
}

export const MainTemplate: React.SFC<IProps> = ({ children, className }) => (
  <div className={classNames(styles.main, className)}>
    <Header />
    {children}
    <Footer />
  </div>
);
