import * as React from 'react';
import { useEffect, useRef } from 'react';

// styles
import * as styles from './MapContainer.sass';

interface IProps {
  className?: string;
}

declare const google: any;

export const MapContainer: React.SFC<IProps> = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#444444'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              color: '#f2f2f2'
            }
          ]
        },
        {
          featureType: 'landscape.natural',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#052366'
            },
            {
              saturation: '-70'
            },
            {
              lightness: '85'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'simplified'
            },
            {
              lightness: '-53'
            },
            {
              weight: '1.00'
            },
            {
              gamma: '0.98'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              saturation: -100
            },
            {
              lightness: 45
            },
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              saturation: '-18'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: '#57677a'
            },
            {
              visibility: 'on'
            }
          ]
        }
      ],
      { name: 'Styled Map' }
    );
    const map = new google.maps.Map(mapRef.current, {
      center: {
        lat: 30.26969,
        lng: -97.73636
      },
      zoom: 12,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map']
      }
    });

    const icon = {
      url: '../../assets/arlos-icon-small.png', // url
      scaledSize: new google.maps.Size(35, 40), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0) // anchor
    };

    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');

    // spiderhouse
    const spiderhouseInfo =
      '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h1 id="firstHeading" style="height: auto; font-size: 20px;" class="firstHeading">Spider House</h1>' +
      '<a href="https://www.google.com/maps/place/Spider+House/@30.295504,-97.741759,15z/data=!4m2!3m1!1s0x0:0x414ca756340f100a?ved=2ahUKEwio88na293gAhUGOa0KHfPCB5QQ_BIwCnoECAUQCA"><h4>2908 Fruth St, Austin, TX 78705</h4></a>' +
      // '<div id="bodyContent">' +
      // 'Lorem ipsum dolor sit amet, ' +
      // 'consectetur adipiscing elit, sed do eiusmod tempor incididunt ' +
      // 'ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud ' +
      // 'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
      // '</div>' +
      '<a target="_blank" href="https://www.toasttab.com/arlosatspiderhouse/v3">' +
      '<button style="background-color: #ec4a25; cursor: pointer; color: white; padding: 5px 15px; font-family: Highway Gothic Italic; text-transform: uppercase; font-size: 18px; outline: none;">Order Here</button></a> ' +
      '</div>' +
      '</div>';

    const spiderhouseInfowindow = new google.maps.InfoWindow({
      content: spiderhouseInfo
    });

    const spiderhouseMarker = new google.maps.Marker({
      position: {
        lat: 30.29552,
        lng: -97.74175
      },
      icon: icon,
      map
    });

    spiderhouseMarker.addListener('click', function() {
      // grackleInfowindow.close();
      cheerUpCharliesInfowindow.close();
      // crowBarInfowindow.close();
      spiderhouseInfowindow.open(map, spiderhouseMarker);
    });

    // // the grackle
    // const grackleInfo =
    //   '<div id="content">' +
    //   '<div id="siteNotice">' +
    //   '</div>' +
    //   '<h1 id="firstHeading" style="height: auto; font-size: 20px;" class="firstHeading">The Grackle</h1>' +
    //   '<a href="https://www.google.com/maps/place/Grackle/@30.2626731,-97.7241668,15z/data=!4m2!3m1!1s0x0:0x944db9d62dd8e917?ved=2ahUKEwisrZjQ293gAhUFTKwKHYa2DKoQ_BIwDnoECAQQCA"><h4>1700 E 6th St, Austin, TX 78702</h4></a>' +
    //   // '<div id="bodyContent">' +
    //   // 'Lorem ipsum dolor sit amet, ' +
    //   // 'consectetur adipiscing elit, sed do eiusmod tempor incididunt ' +
    //   // 'ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud ' +
    //   // 'exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
    //   // '</div>' +
    //   '<a target="_blank" href="https://www.toasttab.com/arlosatgrackle/v3">' +
    //   '<button style="background-color: #ec4a25; cursor: pointer; color: white; padding: 5px 15px; font-family: Highway Gothic Italic; text-transform: uppercase; font-size: 18px; outline: none;">Order Here</button></a> ' +
    //   '</div>' +
    //   '</div>';

    // const grackleInfowindow = new google.maps.InfoWindow({
    //   content: grackleInfo
    // });

    // const grackleMarker = new google.maps.Marker({
    //   position: {
    //     lat: 30.26258,
    //     lng: -97.72417
    //   },
    //   icon: icon,
    //   map
    // });

    // grackleMarker.addListener('click', function() {
    //   spiderhouseInfowindow.close();
    //   cheerUpCharliesInfowindow.close();
    //   crowBarInfowindow.close();
    //   grackleInfowindow.open(map, grackleMarker);
    // });

    // cheer up charlie's
    const cheerUpCharliesInfo =
      '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      "<h1 id='firstHeading' style='height: auto; font-size: 20px;'>Cheer Up Charlie's</h1>" +
      '<a href="https://www.google.com/maps/place/Cheer+Up+Charlies/@30.2696098,-97.7363583,15z/data=!4m5!3m4!1s0x0:0x4c7284d2fb8c9775!8m2!3d30.2696098!4d-97.7363583"><h4>900 Red River St, Austin, TX 78701</h4></a>' +
      '<a target="_blank" href="https://www.toasttab.com/arlosatcheerupcharlies/v3">' +
      '<button style="background-color: #ec4a25; cursor: pointer; color: white; padding: 5px 15px; font-family: Highway Gothic Italic; text-transform: uppercase; font-size: 18px; outline: none;">Order Here</button></a> ' +
      '</div>' +
      '</div>';

    const cheerUpCharliesInfowindow = new google.maps.InfoWindow({
      content: cheerUpCharliesInfo
    });

    const cheerUpCharliesMarker = new google.maps.Marker({
      position: {
        lat: 30.26969,
        lng: -97.73636
      },
      icon: icon,
      map
    });

    cheerUpCharliesMarker.addListener('click', function() {
      spiderhouseInfowindow.close();
      // grackleInfowindow.close();
      // crowBarInfowindow.close();
      cheerUpCharliesInfowindow.open(map, cheerUpCharliesMarker);
    });

    // crow bar
    // const crowBarInfo =
    //   '<div id="content">' +
    //   '<div id="siteNotice">' +
    //   '</div>' +
    //   '<h1 id="firstHeading" style="height: auto; font-size: 20px;">Crow Bar</h1>' +
    //   '<a href="https://goo.gl/maps/GhjLmCBLQv127X3UA"><h4>3116 S Congress Ave, Austin, TX 78704</h4></a>' +
    //   '<a target="_blank" href="https://www.toasttab.com/arlosatcrowbar/v3">' +
    //   '<button style="background-color: #ec4a25; cursor: pointer; color: white; padding: 5px 15px; font-family: Highway Gothic Italic; text-transform: uppercase; font-size: 18px; outline: none;">Order Here</button></a> ' +
    //   '</div>' +
    //   '</div>';

    // const crowBarInfowindow = new google.maps.InfoWindow({
    //   content: crowBarInfo
    // });

    // const crowBarMarker = new google.maps.Marker({
    //   position: {
    //     lat: 30.2312748,
    //     lng: -97.759507
    //   },
    //   icon: icon,
    //   map
    // });

    // crowBarMarker.addListener('click', function() {
    //   spiderhouseInfowindow.close();
    //   grackleInfowindow.close();
    //   cheerUpCharliesInfowindow.close();
    //   crowBarInfowindow.open(map, crowBarMarker);
    // });
  }, []);

  return <div className={styles.map} ref={mapRef} />;
};
