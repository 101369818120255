exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'Highway Gothic Italic';\n  font-style: normal;\n  font-weight: 100;\n  src: url(\"assets/HWYGNRRW.TTF\"); }\n\n@font-face {\n  font-family: 'Highway Gothic Condensed';\n  font-style: normal;\n  font-weight: 100;\n  src: url(\"assets/HWYGCOND.TTF\"); }\n\n@font-face {\n  font-family: 'Highway Gothic';\n  font-style: normal;\n  font-weight: 100;\n  src: url(\"assets/HWYGOTH.TTF\"); }\n\n.ResponsiveMenu__main__B_j-y {\n  font-family: 'Highway Gothic Condensed';\n  font-size: 1.5em;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  display: none;\n  text-align: right;\n  width: 0;\n  height: 100vh;\n  z-index: 20;\n  transition: width 0.5s; }\n  @media screen and (max-width: 812px) {\n    .ResponsiveMenu__main__B_j-y {\n      display: block; } }\n\n.ResponsiveMenu__menuLinks__1nqM- {\n  width: 0;\n  height: 100vh;\n  display: grid;\n  grid-template-columns: 1fr;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px;\n  transition: 0.7s ease;\n  background: rgba(0, 0, 0, 0.8);\n  float: right;\n  align-content: center;\n  opacity: 1;\n  transition: width 0.5s ease-in-out; }\n\n.ResponsiveMenu__menuLink__1albB {\n  font-family: 'Highway Gothic Condensed';\n  color: white;\n  width: 100%;\n  text-align: center;\n  text-transform: uppercase;\n  padding: 10px 0 5px 0;\n  white-space: nowrap; }\n", ""]);

// exports
exports.locals = {
	"main": "ResponsiveMenu__main__B_j-y",
	"menuLinks": "ResponsiveMenu__menuLinks__1nqM-",
	"menuLink": "ResponsiveMenu__menuLink__1albB"
};