import * as classNames from 'classnames';
import * as React from 'react';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Footer.sass';

interface IProps {
  className?: string;
}

export const Footer: React.SFC<IProps> = ({ className }) => (
  <div className={classNames(styles.main, className)}>
    <Container className={styles.mainContainer}>
      <div>
        <div className={styles.container}>
          <span className={styles.followText}>Follow</span>&nbsp;
          <span className={styles.usText}>us</span>
        </div>
        <div className={styles.container1}>
          <a href="https://instagram.com/arloscurbside" target="_blank">
            <img src="../../assets/instagram-icon.png" />
          </a>
          <a href="https://twitter.com/arloscurbside" target="_blank">
            <img src="../../assets/twitter-icon.png" />
          </a>
          <a href="https://facebook.com/arlostruck" target="_blank">
            <img src="../../assets/facebook-icon.png" />
          </a>
        </div>
        <div className={styles.container2}>
          <a href="/">Privacy Policy</a>
          <a href="/">Terms of Use</a>
        </div>
        <div className={styles.container3}>&copy; Arlo’s Curbside 2019</div>
      </div>
    </Container>
  </div>
);
