import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';


// components
import { ModalLink } from '../../components/modalLink/ModalLink'

// contexts
import { ModalContext } from '../../contexts';

// styles
import * as styles from './Location.sass';

interface IProps {
  className?: string;
  url: string;
  photo: string;
  title: string;
  address: string;
  hours: string;
  phone: string;
  photoHeight?: number;
  googleMaps: string;
}

// interface ILabelProps {
//   className?: string;
//   status: string;
// }

interface IButtonProps {
  className?: string;
  url: string;
}

// const Label: React.SFC<ILabelProps> = ({ status }) => {
//   if (status === 'new') {
//     return <img src="../../assets/new.svg" className={styles.label} />;
//   } else if (status === 'soon') {
//     return <img src="../../assets/soon.svg" className={styles.label} />;
//   } else {
//     return null;
//   }
// };

const Button: React.SFC<IButtonProps> = ({ url }) => (
  <a className={styles.orderHere}>
    Order Here
  </a>
);

// template
export const Location: React.SFC<IProps> = ({
  className,
  url,
  photo,
  title,
  address,
  hours,
  phone,
  googleMaps,
  photoHeight
}) => {
  const { setShowModal } = useContext(ModalContext);

  const getModalContent = () => {
    return (
      <>
        <div className={styles.modalIconContainer}>
          <img src='/assets/arlos-icon.svg' />
        </div>
        <div className={styles.modalMessage}>{`We have multiple locations! Are you sure you want to order from Arlo's at ${title}?`}</div>
        <div className={styles.modalButtonContainer}>
          <a target="_blank" href={url}><button className={styles.modalButtonBlue} onClick={() => { setShowModal(false) }}>Yes</button></a>
          <button className={styles.modalButtonRed} onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      </>
    )
  }

  return (
    <div className={classNames(styles.location, className)}>
      <div>
        <div className={styles.titleContainer}>
          <img style={{ height: `${photoHeight}px`, maxHeight: 'none' }} className={styles.title} src={photo} />
        </div>
        {/* <Label className={styles.label} status={status} /> */}
        {/* <img className={styles.image} src={photo} /> */}
        <ModalLink content={getModalContent()}>
          <Button url={url} />
        </ModalLink>
        <div className={styles.text}>
          <img src="../../assets/map-icon.svg" className={styles.iconMap} />
          <a href={googleMaps}>
            <div>{address}</div>
          </a>
        </div>
        <div className={styles.text}>
          <img src="../../assets/smartphone-call.svg" className={styles.iconPhone} />
          <a href={`tel:${phone}`}>
            <div>{phone}</div>
          </a>
        </div>
        <div className={styles.text}>
          <img src="../../assets/clock-icon.svg" className={styles.iconClock} />
          <div>{hours}</div>
        </div>
      </div>
    </div>
  )
};
