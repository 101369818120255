import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// contexts
import { PageContext } from '../../contexts';

// components
import { Contact } from '../../components/contact/Contact';
import { Newsletter } from '../../components/newsletter/Newsletter';

// styles
import * as styles from './ContactPage.sass';

interface IProps {
  className?: string;
}

export const ContactPage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage('contact');
  }, []);

  return (
    <div className={classNames(styles.contact, className)}>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Arlos Contact" />
        <meta name="keywords" content="contact,arlos" />
      </Helmet>
      <Contact />
      <Newsletter />
      <img className={styles.onion} src="../../assets/onion.png" />
    </div>
  );
};
