import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Container } from '../../components/container/Container';

// contexts
import { PageContext } from '../../contexts';

// styles
import * as styles from './ErrorPage.sass';

interface IProps {
  className?: string;
}

export const ErrorPage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext)

  useEffect(() => {
    setPage('');
  }, []);

  return (
    <div className={classNames(styles.errorPage, className)}>
      <Helmet>
        <title>404</title>
        <meta name="description" content="404 - Arlos" />
      </Helmet>
      <Container>
        <h1>404 - Page Not Found</h1>
      </Container>
    </div>
  );
};
