import * as React from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface IProps {
  currentMerchView: string;
  setCurrentMerchView: Dispatch<SetStateAction<string>>;
}

export const MerchContext = createContext<IProps>({
  currentMerchView: '',
  setCurrentMerchView: () => {}
});

export const MerchContextProvider: React.SFC = props => {
  const [currentMerchView, setCurrentMerchView] = useState('all');

  const value = { currentMerchView, setCurrentMerchView };

  return <MerchContext.Provider value={value}>{props.children}</MerchContext.Provider>;
};

export const MerchContextConsumer = MerchContext.Consumer;
