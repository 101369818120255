import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// contexts
import { PageContext } from '../../contexts';

// components
import { Locations } from '../../components/locations/Locations';
import { MapContainer } from '../../components/mapContainer/MapContainer';
import { Newsletter } from '../../components/newsletter/Newsletter';

// styles
import * as styles from './LocationsPage.sass';

interface IProps {
  className?: string;
}

export const LocationsPage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage('locations');
  }, []);

  return (
    <div className={classNames(styles.locations, className)}>
      <Helmet>
        <title>Locations</title>
        <meta name="description" content="Arlos Locations" />
        <meta name="keywords" content="locations,arlos" />
      </Helmet>
      <MapContainer />
      <img className={styles.salad} src="../../assets/salad.png" />
      {/* <img className={styles.jalapeno} src="../../assets/jalapeno.png" /> */}
      <Locations />
      <Newsletter />
      <img className={styles.pickles} src="../../assets/pickles.png" />
    </div>
  );
};
