exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ErrorPage__errorPage__YYpii {\n  margin: 0 auto; }\n", ""]);

// exports
exports.locals = {
	"errorPage": "ErrorPage__errorPage__YYpii"
};