import * as classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Order.sass';

interface IProps {
  className?: string;
}

export const Order: React.SFC<IProps> = ({ className }) => (
  <div className={classNames(styles.order, className)}>
    <Container className={styles.container}>
      <div className={styles.left}>
        <div>
          <div className={styles.newText}>New!</div>
          <div className={styles.info}>
            <div>
              <h1>Order Online</h1>
            </div>
            <p>
             We make all of our food fresh on site with dedication and love. Our menu is 100% plant-based, because we think plant-based food done right is awesome.
            </p>
            <div className={styles.buttonsContainer}>
              <a className={styles.orderOnline} href="/order">Order Online</a>
              <Link className={styles.viewMenu} to="/menu">View Menu</Link>
            </div>
          </div>
        </div>
      </div>
      <img src="../../assets/order.png" className={styles.right} />
    </Container>
  </div>
);
