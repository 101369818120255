import * as classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Photos.sass';

interface IProps {
  className?: string;
}

export const Photos: React.SFC<IProps> = ({ className }) => {
  const images = [
    '../../assets/tacos.jpg',
    '../../assets/platter.png',
    '../../assets/nachos.png',
    '../../assets/tacos2.png'
  ];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const getBarStatus = () => {
    if (currentSlideIndex === 0) {
      return styles.activeBar;
    } else if (currentSlideIndex === 1) {
      return styles.activeBar2;
    } else if (currentSlideIndex === 2) {
      return styles.activeBar3;
    } else if (currentSlideIndex === 3) {
      return styles.activeBar4;
    } else {
      return styles.activeBar;
    }
  };

  const nextSlide = () => {
    if (currentSlideIndex === images.length - 1) {
      setCurrentSlideIndex(0);
    } else {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const previousSlide = () => {
    if (currentSlideIndex === 0) {
      setCurrentSlideIndex(images.length - 1);
    } else {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const numberClick = (indexOfNumberClicked: number) => {
    setCurrentSlideIndex(indexOfNumberClicked);
  };

  return (
    <div className={classNames(styles.main, className)}>
      <Container className={styles.containerDesktop}>
        <div className={styles.container}>
          <span className={styles.collectionOfText}>Collection of</span>&nbsp;
          <span className={styles.photosText}>photos</span>
        </div>
        <div className={styles.photosContainer}>
          <div className={styles.arrowContainerLeft} onClick={() => previousSlide()}>
            <img className={styles.arrow} src="../../assets/left-arrow.png" />
          </div>
          <img className={styles.photo} src={images[currentSlideIndex]} />
          <div className={styles.arrowContainerRight} onClick={() => nextSlide()}>
            <img className={styles.arrow} src="../../assets/right-arrow.png" />
          </div>
        </div>
        <div className={styles.photoNumbersContainer}>
          <div
            className={classNames(
              styles.photoNumber,
              currentSlideIndex === 0 ? styles.active : null
            )}
            onClick={() => numberClick(0)}
          >
            01
          </div>
          <div>-</div>
          <div
            className={classNames(
              styles.photoNumber,
              currentSlideIndex === 1 ? styles.active : null
            )}
            onClick={() => numberClick(1)}
          >
            02
          </div>
          <div>-</div>
          <div
            className={classNames(
              styles.photoNumber,
              currentSlideIndex === 2 ? styles.active : null
            )}
            onClick={() => numberClick(2)}
          >
            03
          </div>
          <div>-</div>

          <div
            className={classNames(
              styles.photoNumber,
              currentSlideIndex === 3 ? styles.active : null
            )}
            onClick={() => numberClick(3)}
          >
            04
          </div>
        </div>
        <div className={styles.barContainer}>
          <div>
            <div className={styles.grayBar} />
            <div className={`${getBarStatus()}`} />
          </div>
        </div>
      </Container>
      <Container className={styles.containerMobile}>
        <img src="../../assets/tacos.jpg" className={styles.mobilePhoto1} />
        <img src="../../assets/platter.png" className={styles.mobilePhoto2} />
        <img src="../../assets/nachos.png" className={styles.mobilePhoto3} />
        <img src="../../assets/tacos2.png" className={styles.mobilePhoto4} />
      </Container>
    </div>
  );
};
