exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LocationsPage__jalapeno__mAwoN {\n  position: absolute;\n  right: -10px;\n  width: 100px;\n  margin-top: 40px; }\n  @media screen and (max-width: 1000px) {\n    .LocationsPage__jalapeno__mAwoN {\n      display: none; } }\n\n.LocationsPage__locations__2kO7O {\n  margin: 0 auto; }\n\n.LocationsPage__map__2ZOkt {\n  width: 100%;\n  height: auto; }\n\n.LocationsPage__onion__KIXP_ {\n  position: absolute;\n  left: -30px;\n  margin-top: 600px; }\n  @media screen and (max-width: 1000px) {\n    .LocationsPage__onion__KIXP_ {\n      display: none; } }\n\n.LocationsPage__pickles__DdHbA {\n  position: absolute;\n  right: -40px;\n  width: 150px;\n  margin-top: -80px; }\n  @media screen and (max-width: 1000px) {\n    .LocationsPage__pickles__DdHbA {\n      display: none; } }\n\n.LocationsPage__salad__2_Fuz {\n  position: absolute;\n  left: 0;\n  width: 100px; }\n  @media screen and (max-width: 1000px) {\n    .LocationsPage__salad__2_Fuz {\n      display: none; } }\n\n.LocationsPage__tomato__2QVmK {\n  position: absolute;\n  right: -30px;\n  margin-top: 900px; }\n  @media screen and (max-width: 1000px) {\n    .LocationsPage__tomato__2QVmK {\n      display: none; } }\n", ""]);

// exports
exports.locals = {
	"jalapeno": "LocationsPage__jalapeno__mAwoN",
	"locations": "LocationsPage__locations__2kO7O",
	"map": "LocationsPage__map__2ZOkt",
	"onion": "LocationsPage__onion__KIXP_",
	"pickles": "LocationsPage__pickles__DdHbA",
	"salad": "LocationsPage__salad__2_Fuz",
	"tomato": "LocationsPage__tomato__2QVmK"
};