exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'Highway Gothic Italic';\n  font-style: normal;\n  font-weight: 100;\n  src: url(\"assets/HWYGNRRW.TTF\"); }\n\n@font-face {\n  font-family: 'Highway Gothic Condensed';\n  font-style: normal;\n  font-weight: 100;\n  src: url(\"assets/HWYGCOND.TTF\"); }\n\n@font-face {\n  font-family: 'Highway Gothic';\n  font-style: normal;\n  font-weight: 100;\n  src: url(\"assets/HWYGOTH.TTF\"); }\n\n.Menu__active__3Tegc {\n  border-bottom: 1px solid white; }\n\n.Menu__menu__2tnQ0 {\n  text-align: right;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  height: 100px;\n  margin-right: 10px;\n  font-family: 'Highway Gothic Condensed';\n  font-weight: 400;\n  font-style: normal;\n  text-transform: uppercase;\n  width: 1000px; }\n  @media screen and (max-width: 812px) {\n    .Menu__menu__2tnQ0 {\n      display: none;\n      width: 500px; } }\n\n.Menu__menuList__OiEOx {\n  list-style: none;\n  margin: 0;\n  padding: 0; }\n\n.Menu__menuListItem__Ya5iF {\n  display: inline-block; }\n\n.Menu__menuListItemLink__3Lr_J {\n  font-size: 1em;\n  color: white;\n  text-decoration: none;\n  padding: 5px 15px;\n  letter-spacing: 1px; }\n  .Menu__menuListItemLink__3Lr_J:hover {\n    color: #d1ec8c; }\n\n.Menu__orderOnline__FzkuM {\n  background-color: #e84a26;\n  padding: 5px 8px;\n  margin: 0 5px; }\n", ""]);

// exports
exports.locals = {
	"active": "Menu__active__3Tegc",
	"menu": "Menu__menu__2tnQ0",
	"menuList": "Menu__menuList__OiEOx",
	"menuListItem": "Menu__menuListItem__Ya5iF",
	"menuListItemLink": "Menu__menuListItemLink__3Lr_J",
	"orderOnline": "Menu__orderOnline__FzkuM"
};