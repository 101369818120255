import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Container } from '../../components/container/Container';
import { Newsletter } from '../../components/newsletter/Newsletter';

// contexts
import { PageContext } from '../../contexts';

// styles
import * as styles from './CardPage.sass';

interface IProps {
  className?: string;
}

export const CardPage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage('gift-cards');
  }, []);

  return (
    <div className={classNames(styles.merch, className)}>
      <Helmet>
        <title>Gift Cards</title>
        <meta name="description" content="Arlos Merch" />
        <meta name="keywords" content="merch,arlos" />
      </Helmet>
      <img className={styles.salad} src="../../assets/salad.png" />
      <img className={styles.tomato} src="../../assets/tomato.png" />
      <Container className={styles.merchContainer}>
        <div className={styles.title}>
          <div className={styles.arlosText}>Arlo's</div>
          <div className={styles.merchText}>Gift Cards</div>
        </div>
        <div className={styles.grid}>
          <img className={styles.merchImage} src="../../assets/hero-gift-card-cropped.png" />
          <div className={styles.merchInfoContainer}>
            <div className={styles.flexContainer}>
              <div>
                <div className={styles.cardText}>Gift Card</div>
              </div>
              <div className={styles.priceBackground}>
                <p className={styles.arlosTextWhite}>$5 - $500</p>
              </div>
            </div>
            <div className={styles.descriptionText}>Valid at any Arlo's location</div>

            <a target="_blank" className={styles.buyNowContainer} href="https://www.toasttab.com/arlosatspiderhouse/giftcards">
              <button className={styles.buyNow}>Buy Now</button>
            </a>
          </div>
        </div>
      </Container>
      <Newsletter />
      <img className={styles.onion} src="../../assets/onion.png" />
    </div>
  );
};
