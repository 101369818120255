import * as classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Newsletter.sass';

interface IProps {
  className?: string;
}

export const Newsletter: React.SFC<IProps> = ({ className }) => {
  const [name] = useState('');
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await fetch('https://subscribe.arlos.workers.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email
        })
      });
      setIsComplete(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  }

  if (isComplete) {
    return (
      <div className={classNames(styles.main, className)}>
        <Container className={styles.container}>
          <div className={styles.itemContainer}>
            <img className={styles.newsletterImage} src="../../assets/license-to-grill.svg" />
          </div>
          <div>
            <div className={styles.text}>
              <span className={styles.stayIn}>Stay in</span>&nbsp;
              <span className={styles.theKnow}>the know</span>
            </div>
            <form className={styles.inputContainer} onSubmit={handleSubmit}>
              {errorMessage}
              <input
                className={styles.inputText}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                disabled={isLoading}
                className={styles.inputSubmit}
                type="submit"
                value="Sign Up"
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
            </form>
            <div className={styles.thankYou}>Thank you! You've been added to our mailing list.</div>
          </div>
          <div className={styles.itemContainer}>
            <img className={styles.newsletterImage} src="../../assets/plant-based.svg" />
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={classNames(styles.main, className)}>
      <Container className={styles.container}>
        <div className={styles.itemContainer}>
          <img className={styles.newsletterImage} src="../../assets/license-to-grill.svg" />
        </div>
        <div>
          <div className={styles.text}>
            <span className={styles.stayIn}>Stay in</span>&nbsp;
            <span className={styles.theKnow}>the know</span>
          </div>
          <form className={styles.inputContainer} onSubmit={handleSubmit}>
            {errorMessage}
            <input
              className={styles.inputText}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              disabled={isLoading}
              className={styles.inputSubmit}
              type="submit"
              value="Sign Up"
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </button>
          </form>
        </div>
        <div className={styles.itemContainer}>
          <img className={styles.newsletterImage} src="../../assets/plant-based.svg" />
        </div>
      </Container>
    </div>
  );
};
