exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Header__main__2wSLD {\n  margin: 0 auto; }\n\n.Header__container__2HzmF {\n  display: flex;\n  align-items: center;\n  height: 100px; }\n\n.Header__menuIconButton__1qSkg {\n  width: 30px;\n  height: 30px;\n  display: none;\n  margin-right: 45px;\n  margin-top: 18px;\n  background-image: url(\"../../assets/menu.png\");\n  background-size: cover;\n  background-repeat: no-repeat; }\n  @media screen and (max-width: 812px) {\n    .Header__menuIconButton__1qSkg {\n      display: block;\n      cursor: pointer;\n      margin-left: auto;\n      z-index: 30; } }\n", ""]);

// exports
exports.locals = {
	"main": "Header__main__2wSLD",
	"container": "Header__container__2HzmF",
	"menuIconButton": "Header__menuIconButton__1qSkg"
};