import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Locations } from '../../components/locations/Locations';
import { Newsletter } from '../../components/newsletter/Newsletter';

// contexts
import { PageContext } from '../../contexts';

// styles
import * as styles from './OrderPage.sass';

interface IProps {
  className?: string;
}

export const OrderPage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage('order');
  }, []);

  return (
    <div className={classNames(styles.menu, className)}>
      <Helmet>
        <title>Order</title>
        <meta name="description" content="Arlos Menu" />
        <meta name="keywords" content="menu,arlos" />
      </Helmet>
      <Locations titlePrefix="Pick" titleSuffix="a Location" />
      <Newsletter />
    </div>
  );
};
