import * as classNames from 'classnames';
import * as React from 'react';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Instagram.sass';

interface IProps {
  className?: string;
}

export const Instagram: React.SFC<IProps> = ({ className }) => (
  <div className={classNames(styles.main, className)}>
    <Container>
      <div className={styles.container}>
        <span className={styles.followOnText}>Follow on</span>&nbsp;
        <span className={styles.instagramText}>Instagram</span>
      </div>
      <div className={styles.photosContainer}>
        <div className={styles.photo1} />
        <div className={styles.photo2} />
        <div className={styles.photo3} />
        <div className={styles.photo4} />
        <div className={styles.photo5} />
        <div className={styles.photo6} />
        <div className={styles.photo7} />
        <div className={styles.photo8} />
        <div className={styles.photo9} />
      </div>
      <div className={styles.container}>
        <a href="https://instagram.com/arloscurbside" target="_blank">
          <span className={styles.seeMore}>See More</span>
        </a>
      </div>
      <img className={styles.pickles} src="../../assets/pickles.png" />
    </Container>
  </div>
);
