import classNames from 'classnames';
import * as React from 'react';

// styles
import * as styles from './Logo.sass';

interface IProps {
  className?: string;
}

export const Logo: React.SFC<IProps> = ({ className }) => (
  <a href="/" className={classNames(styles.logo, className)}>
    <img className={styles.navLogo} src="assets/menu-logo.svg" />
  </a>
);
