exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MapContainer__main__ZbpvY {\n  font-family: Verdana; }\n\n.MapContainer__map__2jZaP {\n  height: 400px; }\n\na > h4 {\n  color: blue !important; }\n", ""]);

// exports
exports.locals = {
	"main": "MapContainer__main__ZbpvY",
	"map": "MapContainer__map__2jZaP"
};