exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContactPage__contact__3-Mi3 {\n  margin: 0 auto; }\n\n.ContactPage__onion__3QL9j {\n  position: absolute;\n  right: -20px;\n  margin-top: -100px; }\n  @media screen and (max-width: 800px) {\n    .ContactPage__onion__3QL9j {\n      display: none; } }\n", ""]);

// exports
exports.locals = {
	"contact": "ContactPage__contact__3-Mi3",
	"onion": "ContactPage__onion__3QL9j"
};