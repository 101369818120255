import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// components
import { Container } from '../../components/container/Container';
import { Location } from '../../components/location/Location';

// contexts
import { PageContext } from '../../contexts'

// props

// styles
import * as styles from './Locations.sass';

// template
interface IProps {
  className?: string;
  titlePrefix?: string;
  titleSuffix?: string;
}

let locations = [
  {
    url: 'https://www.toasttab.com/arlosatcheerupcharlies/v3',
    title: "Cheer Up Charlie's",
    photo: '../../assets/cheer-up-charlies-logo.png',
    address: "900 Red River at Cheer Up Charlie's Austin, TX",
    phone: '(512) 840-1600',
    hours: 'Mon-Sun: 3pm - 10pm',
    googleMaps:
      'https://www.google.com/maps/place/Cheer+Up+Charlies/@30.2696098,-97.7363583,15z/data=!4m5!3m4!1s0x0:0x4c7284d2fb8c9775!8m2!3d30.2696098!4d-97.7363583'
  },
  {
    url: 'https://www.toasttab.com/arlosatspiderhouse/v3',
    title: 'Spider House',
    photo: '../../assets/spider-house-logo.png',
    address: '2908 Fruth St at Spider House, Austin, TX',
    phone: '(512) 840-8585',
    hours: 'Mon-Sun: 11am - 12am',
    googleMaps:
      'https://www.google.com/maps/place/Spider+House/@30.295504,-97.741759,15z/data=!4m2!3m1!1s0x0:0x414ca756340f100a?ved=2ahUKEwio88na293gAhUGOa0KHfPCB5QQ_BIwCnoECAUQCA'
  }
  // {
  //   url: 'https://www.toasttab.com/arlosatgrackle/v3',
  //   title: 'The Grackle',
  //   photo: '../../assets/grackle-logo.png',
  //   address: '1700 E 6th St at The Grackle, Austin, TX',
  //   phone: '(512) 840-1840',
  //   hours: 'Mon-Sun: 11am - 12am',
  //   googleMaps:
  //     'https://www.google.com/maps/place/Grackle/@30.2626731,-97.7241668,15z/data=!4m2!3m1!1s0x0:0x944db9d62dd8e917?ved=2ahUKEwisrZjQ293gAhUFTKwKHYa2DKoQ_BIwDnoECAQQCA'
  // },
  // {
  //   url: 'https://www.toasttab.com/arlosatcrowbar',
  //   title: 'Crow Bar',
  //   photo: '../../assets/crowbar-logo.png',
  //   address: '3116 S Congress at Crow Bar, Austin, TX',
  //   phone: '(512) 904-0095',
  //   hours: 'Mon-Sun: 5pm - 12am',
  //   googleMaps:
  //     'https://goo.gl/maps/JY95HX9zCtBWfTDN6'
  // }
];

// template
export const Locations: React.SFC<IProps> = ({
  className,
  titlePrefix = 'Our',
  titleSuffix = 'Locations'
}) =>  { 
  const { page } = useContext(PageContext);

  return (
  <div className={classNames(styles.locations, className)}>
    <img className={styles.salad} src="../../assets/salad.png" />
    <Container className={styles.container}>
      <div className={styles.titleContainer}>
        <span className={styles.ourText}>{titlePrefix}</span>&nbsp;
        <span className={styles.locationsText}>{titleSuffix}</span>
      </div>
      <div className={styles.grid}>
        <Location
          // className={styles.col}
          url={locations[0].url}
          title={locations[0].title}
          photo={locations[0].photo}
          address={locations[0].address}
          hours={locations[0].hours}
          phone={locations[0].phone}
          googleMaps={locations[0].googleMaps}
        />
        <Location
          // className={styles.col}
          url={locations[1].url}
          title={locations[1].title}
          photo={locations[1].photo}
          address={locations[1].address}
          hours={locations[1].hours}
          phone={locations[1].phone}
          googleMaps={locations[1].googleMaps}
          photoHeight={70}
        />
        {/* <Location
          // className={styles.col}
          url={locations[2].url}
          title={locations[2].title}
          photo={locations[2].photo}
          address={locations[2].address}
          hours={locations[2].hours}
          phone={locations[2].phone}
          googleMaps={locations[2].googleMaps}
        />
        <Location
          // className={styles.col}
          url={locations[3].url}
          title={locations[3].title}
          photo={locations[3].photo}
          address={locations[3].address}
          hours={locations[3].hours}
          phone={locations[3].phone}
          googleMaps={locations[3].googleMaps}
          // photoHeight={60}
        /> */}
      </div>
      <img className={styles.tomato} src="../../assets/tomato.png" />
      <img className={styles.onion} src="../../assets/onion.png" />
      {page !== 'locations' &&
      <div className={styles.buttonContainer}>
        <Link to="/locations">
          <div className={styles.findLocations}>Find Arlo's Near Me</div>
        </Link>
      </div>
      }
    </Container>
  </div>
)};
