import * as React from 'react';

// components
import { Container } from '../container/Container';
import { Menu } from '../menu/Menu';
import { Logo } from '../logo/Logo';
import { ResponsiveMenu } from '../responsiveMenu/ResponsiveMenu';

// styles
import * as styles from './Header.sass';

export const Header: React.SFC = () => (
  <div className={styles.main}>
    <Container className={styles.container}>
      <Logo />
      <Menu />
      <button
        onClick={() => {
          const menuButton = document.getElementById('menuLinks');
          const menuBackground = document.getElementById('menuBackground');
          menuButton.style.width == '200px'
            ? menuButton.setAttribute('style', 'width: 0')
            : menuButton.setAttribute('style', 'width: 200px');
          menuBackground.style.width == '100vw'
            ? menuBackground.setAttribute('style', 'width: 0')
            : menuBackground.setAttribute('style', 'width: 100vw');
        }}
        className={styles.menuIconButton}
      />
    </Container>
    <ResponsiveMenu />
  </div>
);
