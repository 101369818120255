import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// contexts
import { PageContext } from '../../contexts';

// styles
import * as styles from './Menu.sass';

interface IProps {
  className?: string;
}

export const Menu: React.SFC<IProps> = ({ className }) => {
  const { page } = useContext(PageContext);

  const menuItems = [
    {
      name: 'home',
      text: 'Home',
      url: '/'
    },
    {
      name: 'menu',
      text: 'Menu',
      url: '/menu'
    },
    {
      name: 'locations',
      text: 'Locations',
      url: '/locations'
    },
    {
      name: 'gift-cards',
      text: 'Gift Cards',
      url: '/gift-cards'
    },
    {
      name: 'contact',
      text: 'Contact',
      url: '/contact'
    }
  ];

  return (
    <nav className={classNames(styles.menu)}>
      <ul className={styles.menuList}>
        {menuItems.map((menuItem: any) => (
          <li className={styles.menuListItem} key={menuItem.url}>
            <Link
              className={classNames(
                styles.menuListItemLink,
                menuItem.name === page ? styles.active : null
              )}
              to={menuItem.url}
            >
              {menuItem.text}
            </Link>
          </li>
        ))}
        <li className={classNames(styles.menuListItem, styles.orderOnline)}>
          <Link to="/order" className={styles.menuListItemLink}>
            Order Online
          </Link>
        </li>
      </ul>
    </nav>
  );
};
