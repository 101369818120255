exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MenuPage__jalapeno__2Uo4y {\n  position: absolute;\n  left: 30px;\n  width: 130px;\n  margin-top: -60px; }\n  @media screen and (max-width: 1000px) {\n    .MenuPage__jalapeno__2Uo4y {\n      display: none; } }\n\n.MenuPage__menu__2hXJ2 {\n  margin: 0 auto; }\n\n.MenuPage__onion__2l1jH {\n  position: absolute;\n  right: -30px;\n  margin-top: 1200px; }\n  @media screen and (max-width: 1000px) {\n    .MenuPage__onion__2l1jH {\n      display: none; } }\n\n.MenuPage__pickles__2R6k6 {\n  position: absolute;\n  right: 10%;\n  width: 150px;\n  margin-top: 300px; }\n  @media screen and (max-width: 1000px) {\n    .MenuPage__pickles__2R6k6 {\n      display: none; } }\n\n.MenuPage__salad__11F7f {\n  position: absolute;\n  left: 0;\n  width: 120px;\n  margin-top: 600px; }\n  @media screen and (max-width: 1000px) {\n    .MenuPage__salad__11F7f {\n      display: none; } }\n\n.MenuPage__tomato__3lxLZ {\n  position: absolute;\n  left: -60px;\n  margin-top: 100px; }\n  @media screen and (max-width: 1000px) {\n    .MenuPage__tomato__3lxLZ {\n      margin-top: 50px;\n      width: 150px; } }\n  @media screen and (max-width: 768px) {\n    .MenuPage__tomato__3lxLZ {\n      display: none; } }\n", ""]);

// exports
exports.locals = {
	"jalapeno": "MenuPage__jalapeno__2Uo4y",
	"menu": "MenuPage__menu__2hXJ2",
	"onion": "MenuPage__onion__2l1jH",
	"pickles": "MenuPage__pickles__2R6k6",
	"salad": "MenuPage__salad__11F7f",
	"tomato": "MenuPage__tomato__3lxLZ"
};