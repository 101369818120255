import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Hero2 } from '../../components/hero2/Hero2';
import { Order } from '../../components/order/Order';
import { Locations } from '../../components/locations/Locations';
import { Newsletter } from '../../components/newsletter/Newsletter';
import { Instagram } from '../../components/instagram/Instagram';
import { Photos } from '../../components/photos/Photos';
// import { Testimonials } from '../../components/testimonials/Testimonials';

// contexts
import { PageContext } from '../../contexts';

// styles
import * as styles from './HomePage.sass';

interface IProps {
  className?: string;
}

export const HomePage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage('home');
  }, []);

  return (
    <div className={classNames(styles.home, className)}>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Arlos" />
        <meta name="keywords" content="arlos" />
      </Helmet>
      <Hero2 />
      <Order />
      <Locations />
      <Newsletter />
      <Instagram />
      <Photos />
      {/* <Testimonials /> */}
    </div>
  );
};
