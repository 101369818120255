import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ModalContext } from '../../contexts/modal';
// import classNames from 'classnames';
import * as styles from './Modal.sass';


interface IProps {
  triggerText?: string,
  onClose?: Function,
  onOpen?: Function,
  onKeyDown?: Function
  onClickAway?: Function
}

const ModalContent = ({ onClose, children, onKeyDown, modalRef, buttonRef, onClickAway }) => {

  return ReactDOM.createPortal(
    <aside aria-modal="true" role="dialog" tabIndex={-1} className={styles.cModalCover}
      onKeyDown={onKeyDown} onClick={onClickAway}
    >
      <div className={styles.cModal} ref={modalRef}>
        <div className={styles.cModalContent}>
          <button className={styles.cModalClose} ref={buttonRef} onClick={onClose}>
            <span className={styles.uHideVisually}>Close</span>
            <div className={styles.cModalCloseIconContainer}>
              <svg className={styles.cModalCloseIcon} viewBox="0 0 40 40" ><path fill="#ffffff" d="M 10,10 L 30,30 M 30,10 L 10,30"></path></svg>
            </div>
          </button>
          <div className={styles.cModalBody}>
            {children}
          </div>
        </div>
      </div>
    </aside>, document.body
  )
}

export const Modal: React.FC<IProps> = ({ onClose, onOpen, onKeyDown, onClickAway }) => {

  const { showModal, setShowModal, modalContent } = useContext(ModalContext);

  onOpen = () => {
    if (this.closeButtonNode && this.closeButtonNode.focus()) return;
  }

  onClose = () => {
    setShowModal(false);
  }

  onClickAway = (e: Event) => {
    if (this.modalNode && this.modalNode.contains(e.target)) return;
    onClose();
  };

  onKeyDown = ({ keyCode }) => keyCode === 27 && onClose();

  useEffect(() => {
    showModal && onOpen();
  }, [showModal]);

  return (
    <>
      <CSSTransition classNames={
        {
          enter: styles.modalEnter,
          enterActive: styles.modalEnterActive,
          enterDone: styles.modalEnterDone,
          exit: styles.modalExit,
        }
      }
        in={showModal} timeout={200}
        unmountOnExit
        onEnter={() => setShowModal(true)}
        onExited={() => setShowModal(false)}
      >
        <ModalContent modalRef={(n: HTMLElement) => this.modalNode = n} buttonRef={(n: HTMLElement) => this.closeButtonNode = n} onClose={onClose} onKeyDown={onKeyDown} onClickAway={onClickAway}>{modalContent}</ModalContent>
      </CSSTransition>
    </>
  )
}
