import * as classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Newsletter } from '../../components/newsletter/Newsletter';
import { Order } from '../../components/order/Order';
import { Photos } from '../../components/photos/Photos';
import { Statement } from '../../components/statement/Statement';

// contexts
import { PageContext } from '../../contexts';

// styles
import * as styles from './MenuPage.sass';

interface IProps {
  className?: string;
}

export const MenuPage: React.SFC<IProps> = ({ className }) => {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage('menu');
  }, []);

  return (
    <div className={classNames(styles.menu, className)}>
      <Helmet>
        <title>Menu</title>
        <meta name="description" content="Arlos Menu" />
        <meta name="keywords" content="menu,arlos" />
      </Helmet>
      <img className={styles.tomato} src="../../assets/tomato.png" />
      <img className={styles.salad} src="../../assets/salad.png" />
      <img className={styles.onion} src="../../assets/onion.png" />
      <Statement />
      {/* <img className={styles.jalapeno} src="../../assets/jalapeno.png" /> */}
      <Order />
      <Photos />
      <img className={styles.pickles} src="../../assets/pickles.png" />
      <Newsletter />
    </div>
  );
};
