import * as classNames from 'classnames';
import * as React from 'react';

// components
import { Container } from '../../components/container/Container';

// styles
import * as styles from './Statement.sass';

interface IProps {
  className?: string;
}

export const Statement: React.SFC<IProps> = ({ children, className }) => (
  <div className={classNames(styles.main, className)}>
    <Container>
      <div className={styles.grid}>
        <div className={styles.text}>
          <div>
            <div className={styles.standardsText}>Standards and</div>
            <div className={styles.ingredientsText}>ingredient statement</div>
          </div>
          <p>
            We make all of our food fresh on site with dedication and love. Our menu is 100% plant-based, because we think plant-based food done right is awesome.
          </p>
        </div>
        <img src="../../assets/statement-image.png" className={styles.photo} />
      </div>
    </Container>
    <Container>
      <img src="../../assets/menu-board.png" className={styles.menuBoard} />
    </Container>
  </div>
);
