import * as React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router';
import { Switch } from 'react-router-dom';

// components
import { Modal } from './components/modal/Modal';
import { ScrollToTop } from './components/scrollToTop/ScrollToTop';

// contexts
import { MerchContextProvider } from './contexts/merch';
import { ModalContextProvider } from './contexts/modal';
import { PageContextProvider } from './contexts/page';
import { CategoryContextProvider } from './contexts/category';

// pages
import { ContactPage } from './pages/contact/ContactPage';
import { MenuPage } from './pages/menu/MenuPage';
import { ErrorPage } from './pages/errorPage/ErrorPage';
import { HomePage } from './pages/home/HomePage';
import { LocationsPage } from './pages/locations/LocationsPage';
import { OrderPage } from './pages/order/OrderPage';
import { CardPage } from './pages/card/CardPage';

// templates
import { MainTemplate } from './templates/main/MainTemplate';

// styles
import './App.sass';

const history = createBrowserHistory();

export const App: React.SFC = () => (
  <Router history={history}>
    <ScrollToTop>
      <MainTemplate>
        <Helmet titleTemplate={`%s | Arlo's Curbside`} />
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/contact" component={ContactPage} />
          <Route path="/locations" component={LocationsPage} />
          <Route path="/menu" component={MenuPage} />
          <Route path="/gift-cards" component={CardPage} />
          <Route path="/order" component={OrderPage} />

          <Route component={ErrorPage} />
        </Switch>
      </MainTemplate>
      <Modal />
    </ScrollToTop>
  </Router>
);

export const AppProviders: React.SFC = ({ children }) => (
  <MerchContextProvider>
    <ModalContextProvider>
      <PageContextProvider>
        <CategoryContextProvider>{children}</CategoryContextProvider>
      </PageContextProvider>
    </ModalContextProvider>
  </MerchContextProvider>
);
