import * as React from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface IProps {
  currentCategoryView: string;
  setCurrentCategoryView: Dispatch<SetStateAction<string>>;
}

export const CategoryContext = createContext<IProps>({
  currentCategoryView: '',
  setCurrentCategoryView: () => {}
});

export const CategoryContextProvider: React.SFC = props => {
  const [currentCategoryView, setCurrentCategoryView] = useState('');

  const value = { currentCategoryView, setCurrentCategoryView };

  return <CategoryContext.Provider value={value}>{props.children}</CategoryContext.Provider>;
};

export const CategoryContextConsumer = CategoryContext.Consumer;
