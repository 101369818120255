exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo__logo__-EIw7 {\n  color: #FFF;\n  max-height: 100px;\n  display: flex;\n  align-items: center;\n  width: 175px;\n  padding-left: 15px; }\n\n.Logo__navLogo__1JKcH {\n  width: 175px; }\n", ""]);

// exports
exports.locals = {
	"logo": "Logo__logo__-EIw7",
	"navLogo": "Logo__navLogo__1JKcH"
};