import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

// props
import { ResponsiveMenuProps } from './ResponsiveMenuProps';

// styles
import * as styles from './ResponsiveMenu.sass';

// template
export class ResponsiveMenu extends Component<ResponsiveMenuProps, {}> {
  render() {
    return (
      <div
        id="menuBackground"
        onClick={() => {
          const menuButton = document.getElementById('menuLinks');
          const menuBackground = document.getElementById('menuBackground');
          menuButton.style.width == '200px'
            ? menuButton.setAttribute('style', 'width: 0')
            : menuButton.setAttribute('style', 'width: 200px');
          menuBackground.style.width == '100vw'
            ? menuBackground.setAttribute('style', 'width: 0')
            : menuBackground.setAttribute('style', 'width: 100vw');
        }}
        className={classNames(styles.main, this.props.className)}
      >
        <div id="menuLinks" className={styles.menuLinks}>
          <Link className={styles.menuLink} to="/">
            Home
          </Link>
          <Link className={styles.menuLink} to="/menu">
            Menu
          </Link>
          <Link className={styles.menuLink} to="/locations">
            Locations
          </Link>
          <Link className={styles.menuLink} to="/gift-cards">
            Gift Cards
          </Link>
          <Link className={styles.menuLink} to="/contact">
            Contact
          </Link>
          <Link className={styles.menuLink} to="/order">
            Order Online
          </Link>
        </div>
      </div>
    );
  }
}
